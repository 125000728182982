import '../styles/page-arvssnurran.scss';
import Swiper from '../js/vendor/swiper/swiper.min';

let settings = {
    updateOnWindowResize: true,
    slidesPerView: 1,
    spaceBetween: 130,
    modalView: false,
    loop: false,
    noSwiping: true,
    noSwipingClass: 'quiz-swipper-slide',
    centeredSlides: false,
    navigation: {
        nextEl: '.swiper-button-next-advisor',
        prevEl: '.swiper-button-prev-advisor',
    },
    breakpoints: {
        1200: {
            slidesPerView: 1,
            spaceBetween: 130,
            grabCursor: true,
            loop: false,
            centeredSlides: true,
        },
        719: {
            slidesPerView: 1,
            spaceBetween: 130,
            grabCursor: true,
            loop: false,
            centeredSlides: true,
            initialSlide: 0,
        },
        576: {
            slidesPerView: 1.3,
            spaceBetween: 20,
            grabCursor: true,
            loop: false,
            centeredSlides: true,
            initialSlide: 0,
        }
    }
};
$(document).ready(function () {
    let quizSwiper;
    let quizSwiperModalActive = false;
    let container;
    let answersArr = [];

    const tagsArr = [
        {code: '1111111', tags: '#Arvsnurran, #as_utfall1, #as_gift, #as_barn, #as_barn--med-make/a, #as_barn--ensamstående, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '1111112', tags: '#Arvsnurran, #as_utfall2, #as_gift, #as_barn, #as_barn--med-make/a, #as_barn--ensamstående, #as_änka/änkling'},
        {code: '111112', tags: '#Arvsnurran, #as_utfall3, #as_gift, #as_barn, #as_barn--med-make/a, #as_barn--ensamstående'},
        {code: '1111211', tags: '#Arvsnurran, #as_utfall4, #as_gift, #as_barn, #as_barn--med-make/a, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '1111212', tags: '#Arvsnurran, #as_utfall5, #as_gift, #as_barn, #as_barn--med-make/a, #as_änka/änkling'},
        {code: '111122', tags: '#Arvsnurran, #as_utfall6, #as_gift, #as_barn, #as_barn--med-make/a'},
        {code: '111211', tags: '#Arvsnurran, #as_utfall7, #as_gift, #as_barn, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '111212', tags: '#Arvsnurran, #as_utfall8, #as_gift, #as_barn, #as_änka/änkling'},
        {code: '11122', tags: '#Arvsnurran, #as_utfall9, #as_gift, #as_barn'},
        {code: '11211', tags: '#Arvsnurran, #as_utfall10, #as_gift, #as_barnlös, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '11212', tags: '#Arvsnurran, #as_utfall11, #as_gift, #as_barnlös, #as_änka/änkling'},
        {code: '1122', tags: '#Arvsnurran, #as_utfall12, #as_gift, #as_barnlös'},
        {code: '12111111', tags: '#Arvsnurran, #as_utfall13, #as_sambo, #as_barn, #as_barn--med-sambo, #as_barn--ensamstående, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '12111112', tags: '#Arvsnurran, #as_utfall14, #as_sambo, #as_barn, #as_barn--med-sambo, #as_barn--ensamstående, #as_änka/änkling'},
        {code: '1211112', tags: '#Arvsnurran, #as_utfall15, #as_sambo, #as_barn, #as_barn--med-sambo, #as_barn--ensamstående'},
        {code: '12111211', tags: '#Arvsnurran, #as_utfall16, #as_sambo, #as_barn, #as_barn--med-sambo, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '12111212', tags: '#Arvsnurran, #as_utfall17, #as_sambo, #as_barn, #as_barn--med-sambo, #as_änka/änkling'},
        {code: '1211122', tags: '#Arvsnurran, #as_utfall18, #as_sambo, #as_barn, #as_barn--med-sambo'},
        {code: '12112111', tags: '#Arvsnurran, #as_utfall19, #as_sambo, #as_barn, #as_barn--ensamstående, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '12112112', tags: '#Arvsnurran, #as_utfall20, #as_sambo, #as_barn, #as_barn--ensamstående, #as_änka/änkling'},
        {code: '1211212', tags: '#Arvsnurran, #as_utfall21, #as_sambo, #as_barn, #as_barn--ensamstående'},
        {code: '121211', tags: '#Arvsnurran, #as_utfall22, #as_sambo, #as_barnlös, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '121212', tags: '#Arvsnurran, #as_utfall23, #as_sambo, #as_barnlös, #as_änka/änkling'},
        {code: '12122', tags: '#Arvsnurran, #as_utfall24, #as_sambo, #as_barnlös'},
        {code: '122211', tags: '#Arvsnurran, #as_utfall25, #as_solo, #as_barnlös, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '122212', tags: '#Arvsnurran, #as_utfall26, #as_solo, #as_barnlös, #as_änka/änkling'},
        {code: '12222', tags: '#Arvsnurran, #as_utfall27, #as_solo, #as_barnlös'},
        {code: '122111', tags: '#Arvsnurran, #as_utfall28, #as_solo, #as_barn, #as_änka/änkling, #as_avlidne-make/a-arv-utbetalt'},
        {code: '122112', tags: '#Arvsnurran, #as_utfall29, #as_solo, #as_barn, #as_änka/änkling'},
        {code: '12212', tags: '#Arvsnurran, #as_utfall30, #as_solo, #as_barn'},
    ];
    const questionsArr = [
        { num: '01', question: 'Är du gift?', image: 'q1.jpg', codes: ['1'] },
        { num: '02', question: 'Är du sambo?', image: 'q2.jpg', codes: ['12'] },
        { num: '03', question: 'Har du barn?', image: 'q3.jpg', codes: ['11', '121', '122'] },
        { num: '04', question: 'Med din make/a?', image: 'q3.jpg', codes: ['111'] },
        { num: '05', question: 'Med din sambo?', image: 'q3.jpg', codes: ['1211'] },
        { num: '06', question: 'Från tidigare relation?', image: 'q3.jpg', codes: ['1111', '12111', '12112'] },
        { num: '07', question: 'Är du änka/änkling?', image: 'q4.jpg', codes: ['11111', '11112', '112', '121111', '121112', '121121', '1212', '1222', '1221', '1112'], text: 'Änka/Änkling är man om man tidigare varit gift och detta äktenskap upphört till följd av att en av makarna dött.' },
        { num: '08', question: 'Har din avlidnes make/as arvingar fått ut sitt arv?', image: 'q4.jpg', codes: ['111111', '111121', '111122', '1121', '1211111', '1211121', '1211211', '12121', '12221', '12211', '11121', '11122'], text: 'När man blir änka/änkling ärver man under vissa förutsättningar sin partner. Detta påverkar frågan om vem som ärver dig.' },
    ];
    const finalSteps = tagsArr.reduce((acc, item) => {
        acc.push(item.code);
        return acc;
    }, []);
    const allowedSteps = questionsArr.reduce((acc, item) => {
        acc.push(...item.codes)
        return acc;
    }, []);

    function renderTemplate() {
        const questionItem = questionsArr[0];
        const initialSlide = (index) => (`
            <div class="quiz-swipper-slide swiper-slide initial_slide slide-${index}">
                <img class="close_popup" src="/wp-content/themes/fenix4/dist/images/quiz_images/slider_close_icon.svg" alt="">
                <div class="quiz-swipper-slide-ovelay"></div>
                <div class="quiz-swipper-step">
                    <div>
                        <span class="quiz-swipper-step-number">
                            <span class="quiz-swipper-step-number-big">${index}</span>
                        </span>
                    </div>
                <div>
                <span class="quiz-swipper-progress-title">Vem ärver dig?</span>
                <span class="quiz-swipper-progress-line step_${index}"><span></span></span>
                </div>
                </div>
                <div class="quiz-swipper-content">
                    <div class="quiz-swipper-content-image">
                        <img src="/wp-content/themes/fenix4/dist/images/quiz_images/${questionItem.image ? questionItem.image : 'quiz_icon.svg'}" alt="">
                    </div>
                    <div class="quiz-swipper-content-heading">${questionItem.question}</div>
                    ${questionItem.text ? `<div class="quiz-swipper-content-text">${questionItem.text}</div>` : ''}
                    <div class="quiz-swipper-content-buttons">
                        <button type="button" name="Är du gift?" data-parentcode="1" data-slide="1" data-currentanswer="1" class="btn legal-service-content__step question legal-service-content__step1">Ja</button>
                        <button type="button" name="Är du gift?" data-parentcode="1" data-slide="1" data-currentanswer="2" class="btn legal-service-content__step question legal-service-content__step1">Nej</button>
                    </div>
                </div>
                <button class="go_next_slide disabled" data-slide="${index}"></button>
            </div>
        `);
        return `
            <div id="qInsert"></div>
            <div class="quiz-swipper-container swiper-container">
                <div class="quiz-swipper-wrapper swiper-wrapper">
                    ${!quizSwiperModalActive ? `
                        <div class="quiz-swipper-slide swiper-slide quiz-first_slide initial_slide">
                            <div class="quiz-swipper-content">
                                <div class="first_slide-title">Vem ärver mig?</div>
                                <div class="first_slide-subtitle">Att behöva tänka på vad som händer med ens egendom den dagen man dör kan kännas jobbigt och svårt. Att redan nu ta reda på vad som händer i just din situation kan underlätta både för dig och dina nära och kära. Svara på våra frågor och se hur ditt arv kommer att fördelas och få även svar på vad du kan göra ifall du skulle vilja ändra på arvsordningen.</div>
                                <button class="first_slide-button">Starta <img src="/wp-content/themes/fenix4/dist/images/quiz_images/right_arrow.svg" alt=""></button>
                            </div>
                        </div>
                    ` : ''}
                    ${initialSlide(1)}
                    ${initialSlide(2)}
                </div>
            </div>
            <div class="quote-container quote-arvssnurran-container--title content-container">
                <div class="quote-wrapper quote-arvssnurran-wrapper--title">
                    <h2>Alltid fasta priser</h2>
                    <p class="quote-arvssnurran-wrapper--subtitle">Vi värdesätter ärlighet och använder en transparent prissättning som ni själva anpassar efter er budget. Ni får alltid en kostnadsfri offert med tydliga betalningsvillkor och slipper oroa er för dolda kostnader.</p>
                </div>
            </div>
            ${quizSwiperModalActive ? `
                <div class="quiz-swipper-modal-footer">
                    <a href="/?utm_source=activecampaign&utm_medium=email&utm_campaign=arvsnurran" class="close-quiz-swipper-modal">BESÖK FENIX HEMSIDA</a>
                    <a class="btn btn-primary btn-blue btn-blue--phone btn--shadow" href="tel:010-5517638"><span>Kontakta oss</span></a>
                </div>` : ''}
        `;
    }

    $('#quiz-swipper-desktop').html(renderTemplate())
    container = $(document).find('.quiz-swipper-container');
    quizSwiper = new Swiper(container, settings);

    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('paketmodal') && urlParams.get('paketmodal') === '1' ){
        openQuizPopup();
    }

    function openQuizPopup(){
        $('body').addClass('overflow_hidden');
        if(quizSwiper) {
            quizSwiper.destroy();
        }
        $(document).find('#quiz-swipper-desktop').html('');
        answersArr = [];
        quizSwiperModalActive = true;
        $('.quiz-swipper-modal-content').html(renderTemplate())
        $('.quiz-swipper-modal-content').find('.slide-1').addClass('swiper-slide-active');
        container = $('.quiz-swipper-modal').find('.quiz-swipper-container')
        $('#quiz-swipper-modal').show();
        quizSwiper = new Swiper(container, settings);
        $(window).trigger('resize');
    }

    function addToAnsweredQuestion(slide_number, answer) {
        let slide_index = slide_number - 1;
        let qCode = '1';

        answersArr = answersArr.slice(0, slide_number - 1);
        $('.swiper-slide-active').nextAll('div.quiz-swipper-slide').remove();

        if (answersArr.length > 0) {
            qCode = answersArr[answersArr.length - 1].questionCode.toString() + answersArr[answersArr.length - 1].answer.toString();
        }

        answersArr[slide_index] = {questionCode: qCode, answer: answer};
        let newCode = answersArr[answersArr.length - 1].questionCode.toString() + answersArr[answersArr.length - 1].answer.toString();

        if ($.inArray(newCode, finalSteps) > -1) {
            $('#qInsert').data('finalpage', newCode);
            generateForm();
            if (!$(document).find('.last_step').hasClass('swiper-slide-active')) {
                $(document).find('.last_step').addClass('swiper-slide-active');
            }
        } else if ($.inArray(newCode, allowedSteps) > -1) {
            renderQuestion(newCode, slide_number);
            if (!$(document).find('.last_step').hasClass('swiper-slide-active')) {
                $(document).find('.last_step').addClass('swiper-slide-active');
            }
        } else {
            console.warn('UNKNOWN question Code', newCode);
        }
    }

    function getQNum(qCode) {
        return questionsArr.findIndex(function (qItem) {
            let qIndex = $.inArray(qCode, qItem.codes);
            if (qIndex > -1) {
                return qItem;
            }
        });
    }

    function insertDefaultHtmlToTheSlider(qCode, slider_number) {
        const qNum = getQNum(qCode);
        const s_number = parseInt(slider_number);
        const questionItem = questionsArr[qNum];

        const questionHtml = `
            <div class="quiz-swipper-slide swiper-slide slide-${s_number}">
            <img class="close_popup" src="/wp-content/themes/fenix4/dist/images/quiz_images/slider_close_icon.svg" alt="">
            <div class="quiz-swipper-slide-ovelay"></div>
            <div class="quiz-swipper-step">
            <div>
            <span class="quiz-swipper-step-number">
            <span class="quiz-swipper-step-number-big">${s_number}</span>
            </span>
            </div>
            <div>
            <span class="quiz-swipper-progress-title">Vem ärver dig?</span>
            <span class="quiz-swipper-progress-line step_${s_number}"><span></span></span>
            </div>
            </div>
            <div class="quiz-swipper-content">
            <div class="quiz-swipper-content-image">
            <img src="/wp-content/themes/fenix4/dist/images/quiz_images/${questionItem.image ? questionItem.image : 'quiz_icon.svg'}" alt="">
            </div>
            <div class="quiz-swipper-content-heading">${questionItem.question}</div>
            ${questionItem.text ? `<div class="quiz-swipper-content-text">${questionItem.text}</div>` : ''}
            <div class="quiz-swipper-content-buttons">
            <button type="button" name="${questionItem.question}" data-slide="${s_number}" data-parentcode="${qCode}" data-currentanswer="1" class="btn legal-service-content__step question">Ja</button>
            <button type="button" name="${questionItem.question}" data-slide="${s_number}" data-parentcode="${qCode}" data-currentanswer="2" class="btn legal-service-content__step question">Nej</button>
            </div>
            </div>
            <button class="go_prev_slide" data-slide="${s_number}"></button>
            <button class="go_next_slide disabled" data-slide="${s_number}"></button>
            </div>
        `;
        quizSwiper.appendSlide(questionHtml);
    }

    function renderQuestion(qCode, slide_number = false) {
        let qNum = getQNum(qCode);
        let s_number = parseInt(slide_number) + 1;
        const questionItem = questionsArr[qNum];

        let questionHtml = `
            <div class="quiz-swipper-slide-ovelay"></div>
            <img class="close_popup" src="/wp-content/themes/fenix4/dist/images/quiz_images/slider_close_icon.svg" alt="">
            <div class="quiz-swipper-step">
                <div>
                    <span class="quiz-swipper-step-number">
                        <span class="quiz-swipper-step-number-big">${s_number}</span>
                    </span>
                </div>
                <div>
                    <span class="quiz-swipper-progress-title">Vem ärver dig?</span>
                    <span class="quiz-swipper-progress-line step_${s_number}"><span></span></span>
                </div>
            </div>
            <div class="quiz-swipper-content">
                <div class="quiz-swipper-content-image">
                    <img src="/wp-content/themes/fenix4/dist/images/quiz_images/${questionItem.image ? questionItem.image : 'quiz_icon.svg'}" alt="">
                </div>
                <div class="quiz-swipper-content-heading">${questionItem.question}</div>
                ${questionItem.text ? `<div class="quiz-swipper-content-text">${questionItem.text}</div>` : ''}
                <div class="quiz-swipper-content-buttons">
                    <button type="button" name="${questionItem.question}" data-slide="${s_number}" data-parentcode="${qCode}" data-currentanswer="1" class="btn legal-service-content__step question">Ja</button>
                    <button type="button" name="${questionItem.question}" data-slide="${s_number}" data-parentcode="${qCode}" data-currentanswer="2" class="btn legal-service-content__step question">Nej</button>
                </div>
            </div>
            <button class="go_prev_slide" data-slide="${s_number}"></button>
            <button class="go_next_slide disabled" data-slide="${s_number}"></button>
        `;

        insertHtmlToTheSlider(questionHtml, s_number);
        insertDefaultHtmlToTheSlider(qCode, s_number + 1);
    }

    function insertHtmlToTheSlider(html, slider_number) {
        let s_class = '';
        if (parseInt(slider_number) === 1) {
            s_class = 'initial_slide';
        }

        $(document).find('.slide-' + slider_number).addClass(s_class).html(html);
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validation() {
        let errors = [];
        const inputEmail = $('#inputEmail');

        inputEmail.removeClass('email-error');

        if (inputEmail.val() === '' || !validateEmail(inputEmail.val())) {
            inputEmail.addClass('email-error');
            errors.push('email');
        }

        return errors.length === 0;
    }

    function generateForm() {
        const finalPageCode = $('#qInsert').data('finalpage');
        const formData = getFormData(finalPageCode);

        const formHtml = `
            <div class="quiz-swipper-content-image">
                <img src="/wp-content/themes/fenix4/dist/images/quiz_images/q_final.jpg" alt="">
                <div class="quiz-swipper-content-right-text">
                    <p>Fyll i din mailadress här så skickar vi svar direkt på hur ditt arv kommer att fördelas och även våra rekommendationer om du skulle vilja ändra på arvsordningen.</p>
                </div>
            </div>
            <div class="tab__input-wrapper">
                <form id="tabForm" class="quiz-swipper-content-buttons">
                    <span class="tabForm-required-hint">Mailadress</span>
                    <input id="tagsToCamp" type="hidden" name="tagstocamp" value="${formData.tags}" />
                    <div class="quiz-swipper-tab__input-wrapper">
                        <input id="inputEmail" type="email" class="quiz-swipper tab__input" placeholder="namn@email.com" name="tabepost">
                    </div>
                    <div class="quiz-swipper-email-text">Genom att gå vidare godkänner du att vi lagrar dina uppgifter samt skickar matnyttig information och erbjudanden till dig. Du kan när som helst avregistrera dig från framtida utskick.</div>
                    <button id="legalSubmit" class="btn legal-service-content__step submit" type="submit" style="margin-top: 5px;" value="submit">Maila mig resultatet</button>
                    <div id="tpw-append"></div>
                </form>
            </div>
        `;

        let footer = `
            <div class="quiz-swipper_footer">
                <span class="quiz-swipper_footer-title">Resultat</span>
            </div>
        `;

        const activeSlide = $('body').find('.swiper-slide-active');
        activeSlide.find('.quiz-swipper-content').html(formHtml);
        activeSlide.prepend(footer);
        $("#tpw").appendTo("#tpw-append");
        activeSlide.addClass('quiz-swipper-user-form last_step');
        activeSlide.nextAll('div.quiz-swipper-slide').remove();
    }

    function renderConfirmationBlock(container){
        const block = `
            <div id="confirmation-block" class="confirmation-block">
                <div>
                    <div class="confirmation-block__image"><img src="/wp-content/themes/fenix4/dist/images/quiz_images/q_final.jpg" alt=""></div>
                    <h3>Tack för dina svar</h3>
                    <p>Ditt resultat har skickats den mailadress som du angivit. Vill du ändra mailadress, klicka på knappen nedan.</p>
                    <p>Läs gärna mer om testamente på vår hemsida och hör av dig till oss på Fenix om du har frågor eller funderingar.</p>
                </div>
                <div class="buttons-wrapper">
                    <button id="hide-confirmation-block" class="btn legal-service-content__step back-to-edit-email" type="button">Ändra mailadress</button>
                    <a href="/testamente/?utm_source=activecampaign&utm_medium=email&utm_campaign=arvsnurran" class="btn legal-service-content__step submit confirmation-proceed">Läs mer om testamente</a>
                </div>
            </div>  
        `;
        container.append(block);
        $('#hide-confirmation-block').on('click', function(){
            $('#confirmation-block').remove();
        });
    }

    function closeQuizModal() {
        $('.quiz-swipper-modal').hide();
        $('.quiz-swipper-modal-content').html('');
        answersArr = [];
        quizSwiperModalActive = false;

        $('body').removeClass('overflow_hidden');

        $('.quiz-swipper.lawyer-section-arvssnurran').html(renderTemplate());
        container = $('#quiz-swipper-desktop').find('.quiz-swipper-container')
        quizSwiper = new Swiper(container, settings);

        $(window).trigger('resize');
    }

    function getFormData(qCode) {
        let index = tagsArr.findIndex(function (item) {
            return item.code === qCode;
        });
        return tagsArr[index];
    }

    $('body').on('click', '.go_next_slide, .first_slide-button', function () {
        quizSwiper.slideNext();
    });

    $('body').on('click', '.go_prev_slide', function () {
        quizSwiper.slidePrev();
    });

    $('body').on('click', '.close_popup', function () {
        closeQuizModal();
    });

    $('body').on('click', '.legal-service-content__step.question:not(.button_active)', function () {
        $(this).parent().find('.button_active').removeClass('button_active');
        $(this).addClass('button_active');
        let answer = $(this).data('currentanswer').toString();
        let slide_number = parseInt($(this).data('slide'));

        quizSwiper.slideNext();

        addToAnsweredQuestion(slide_number, answer);
        $(this).closest('.quiz-swipper-slide').find('.go_next_slide').removeClass('disabled');
        $(document).find('.slide-' + (slide_number + 1)).addClass('swiper-slide-active');
    });

    $('body').on('submit','#tabForm', function(e){
        const button = document.getElementById('legalSubmit');
        button.disabled = true;

        let validated = validation();
        if (!validated) {
            button.disabled = false;
            return false;
        }

        let form_name = $('#qInsert').data('finalpage').toString();
        let e_mail = $('#inputEmail').val();
        let tags = $('#tagsToCamp').val();
        $.ajax({
            type: 'POST',
            url: '/api/inheritance/questionnaire/',
            data: {name: form_name, email: e_mail, tags: tags},
            dataType: "json"
        }).done((data, textStatus ) => {
            if (textStatus === 'success' && data.success){
                renderConfirmationBlock($('#legalSubmit').closest('.quiz-swipper-content'));
            }
        }).fail(( jqXHR, textStatus, errorThrown ) => {
            console.log('Error:', textStatus, errorThrown);
        }).always(() => {
            button.disabled = false;
        });

        e.preventDefault();
    });
});